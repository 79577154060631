import { Box, styled } from "@material-ui/core";
import { CustomText, NameIcon } from "common/Reusables";
import React from "react";
import { PinIcon } from "../Icons";
import { conversationTimeFormat, shortenString } from "../util";
import MarkdownIt from "markdown-it";
const HoverBox = styled(Box)({
  "&:hover": {
    backgroundColor: "#EAF8FA",
  },
});
const GroupCard = ({
  group,
  selectedGroup,
  handleGroupMessage,
  groupNotification,
  isTypingGroup,
  user,
  unread,
  isForwardComponent = false,
}) => {
  const [unreadCount, setUnreadCount] = React.useState(unread);
  const [lastMessage, setLastMessage] = React.useState({
    message: group?.last_message?.message,
    time: conversationTimeFormat(group?.last_message?.created_at),
    message_type: group?.last_message?.message_type,
  });

  React.useEffect(() => {
    if (groupNotification && groupNotification?.group === group?.id) {
      setLastMessage({
        message: groupNotification?.message,
        time: conversationTimeFormat(groupNotification?.created_at),
        message_type: groupNotification?.message_type,
      });

      if (groupNotification?.sender !== user?.id) {
        setUnreadCount(unreadCount + 1);
      }
    }
    return () => {
      setUnreadCount(0);
    };
  }, [groupNotification]);

  return (
    <HoverBox
      sx={{
        bgcolor: group?.id === selectedGroup ? "#D6F2F5" : "#fff",
        width: "100%",
        borderBottom: !isForwardComponent && "1px solid #E1E1E1",
        paddingBottom: "13px",
        paddingTop: "14px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "7px",
        cursor: "pointer",
      }}
      onClick={() => {
        handleGroupMessage(group);
        setUnreadCount(0);
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <NameIcon first={group?.name[0]} />
        <Box width={"100%"}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <CustomText
                color="#545454"
                fontSize="14px"
                fontWeight="600"
                fontFamily="Black Sans"
                style={{
                  textTransform: "capitalize",
                }}
              >{`${group?.name}`}</CustomText>
              {group?.pin && !isForwardComponent && <PinIcon />}
            </Box>
            {lastMessage.time && !isForwardComponent && (
              <CustomText
                fontSize="12px"
                fontFamily="Black Sans"
                color="#000"
                className="job"
              >
                {lastMessage.time}
              </CustomText>
            )}
          </Box>

          {!isForwardComponent ? (
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {isTypingGroup &&
              isTypingGroup?.room_id === group?.id &&
              isTypingGroup?.message !== "false" &&
              isTypingGroup?.sender_id !== user?.id ? (
                <CustomText
                  fontSize="14px"
                  fontFamily="Black Sans"
                  color="rgba(84, 84, 84, 0.60)"
                >
                  {isTypingGroup?.message}
                </CustomText>
              ) : lastMessage.message_type === "text" ? (
                <CustomText
                  fontSize="14px"
                  fontFamily="Black Sans"
                  color="rgba(84, 84, 84, 0.60)"
                  className="preview-text "
                  dangerouslySetInnerHTML={
                    groupNotification && groupNotification?.group === group?.id
                      ? { __html: groupNotification.message }
                      : {
                          __html: group?.last_message?.message,
                        }
                  }
                ></CustomText>
              ) : lastMessage.message_type === "image" ? (
                <i>Sent an image</i>
              ) : lastMessage.message_type === "pdf" ||
                lastMessage.message_type === "doc" ? (
                <i>Sent a file</i>
              ) : lastMessage.message_type === "video" ? (
                <i>Sent a video</i>
              ) : lastMessage.message_type === "shared_task" ? (
                <i>Task is shared</i>
              ) : null}

              {unreadCount > 0 && (
                <Box
                  sx={{
                    bgcolor: "#849EE1",
                    height: "16px",
                    width: "16px",
                    borderRadius: "16px",
                    fontSize: "10px",
                    color: "#fff",
                    padding: "2px 5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {unreadCount}
                </Box>
              )}
            </Box>
          ) : (
            <CustomText
              fontSize="14px"
              fontFamily="Black Sans"
              color="rgba(84, 84, 84, 0.60)"
              className="job"
            >
              {group?.description}
            </CustomText>
          )}
        </Box>
      </Box>

      {/* <Box>
        <CustomText
          fontSize="12px"
          fontFamily="Black Sans"
          color="#000"
          className="job"
        >
          {time}
        </CustomText>
        {unread > 0 && (
          <Box
            sx={{
              bgcolor: "#849EE1",
              height: "20px",
              maxWidth: "45px",
              borderRadius: "13px",
              fontSize: "10px",
              color: "#fff",
              padding: "2px 5px",
            }}
          >
            {unread}
          </Box>
        )}
      </Box> */}
    </HoverBox>
  );
};

export default GroupCard;
