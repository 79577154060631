import {
  Box,
  Button,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { LockIcon } from "../Icons";

const GroupSetting = ({ handleCreateGroupChange, groupDetails }) => {
  return (
    <Box mt={"25px"}>
      <Box
        style={{
          padding: "0px 17px",
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div>
          <InputLabel
            style={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Black Sans",
              color: "#545454",
            }}
          >
            Enter Group Name
          </InputLabel>
          <TextField
            variant="outlined"
            size="small"
            name="name"
            defaultValue={groupDetails?.name}
            onChange={handleCreateGroupChange}
            fullWidth
          />
        </div>
        <div>
          <InputLabel
            style={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Black Sans",
              color: "#545454",
            }}
          >
            Group Description (Optional)
          </InputLabel>
          <TextField
            name="descriptions"
            variant="outlined"
            size="small"
            fullWidth
            multiline
            defaultValue={groupDetails?.descriptions}
            minRows="2"
            onChange={handleCreateGroupChange}
          />
        </div>
        <div>
          <InputLabel
            style={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Black Sans",
              color: "#545454",
              marginBottom: "10px",
            }}
          >
            Group Members
          </InputLabel>

          <Box
            sx={{
              borderRadius: "5px",
              border: "1px solid #E1E1E1",
              bgcolor: "#F2F2F2",
              padding: "13px 67px 14px 10px",
              display: "flex",
              flexWrap: "wrap",
              gap: "15px",
            }}
          >
            {groupDetails?.members?.map((member) => (
              <Box
                key={member.id}
                sx={{
                  bgcolor: "#fff",
                  borderRadius: "40px",
                  padding: "8px",
                }}
              >{`${member?.first_name} ${member?.last_name}`}</Box>
            ))}
          </Box>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Black Sans",
              color: "#545454",
            }}
          >
            Group Access:
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Black Sans",
              color: "#545454",
            }}
          >
            This is set to restricted by default and only member of the group
            will have access
          </Typography>
          <Box
            sx={{
              bgcolor: "#F2F2F2",
              height: "70px",
              width: "611px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              gap: "16px",
              paddingLeft: "20px",
            }}
          >
            <LockIcon />
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Black Sans",
                color: "#545454",
              }}
            >
              This group is restricted.
              <br />
              <span
                style={{
                  color: "#878787",
                }}
              >
                {" "}
                Only members added to this group have access to this group.
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GroupSetting;
