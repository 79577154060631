import { Avatar, Box } from "@material-ui/core";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";

export const CustomText = styled(Box)`
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "17.1382px")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "151.36%")};
  color: ${(props) => (props.color ? props.color : "#444444")};
  margin: ${(props) => props.margin};
  text-decoration: ${(props) =>
    props.value === "subtask" ? "line-through" : ""};
  transition: all ease-in-out 250ms;
  &:hover {
    /* background-color: #ADE5EB; */
    background-color: ${(props) =>
      props.value === "description"
        ? "transparant"
        : props.value === "title"
        ? "#ADE5EB"
        : ""};
    padding: ${(props) =>
      props.value === "description"
        ? "15px"
        : props.value === "title"
        ? "15px"
        : ""};
    border-radius: ${(props) =>
      props.value === "description"
        ? "5px"
        : props.value === "title"
        ? "7px"
        : ""};
    border: ${(props) =>
      props.value === "description"
        ? "1px solid #E1E1E1"
        : props.value === "title"
        ? ""
        : ""};
    transition: all ease-in-out 250ms;
  }
`;
CustomText.propTypes = {
  fontWeight: PropTypes.string,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  color: PropTypes.string,
  margin: PropTypes.string,
};
export const FlexBox = styled(Box)`
  display: flex;
  /* flex-direction: ${(props) => props.direction}; */
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "space-between")};
  gap: ${(props) => (props.gap ? props.gap : "")};
`;

export const CustomButton = styled.button`
  height: ${(props) => (props.height ? props.height : "40px")};
  background: ${(props) => (props.background ? props.background : "#2898a4")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "49px"};
  border: ${(props) => (props.border ? props.border : "none")};
  color: ${(props) => (props.color ? props.color : "white")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "17.1382px")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "16px")};
  padding: ${(props) => (props.padding ? props.padding : "0 1rem")};
  cursor: pointer;
  margin: ${(props) => props.margin};
  text-decoration: ${(props) => (props.decoration ? props.decoration : "none")};
  width: ${(props) => (props.width ? props.width : "auto")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

CustomButton.propTypes = {
  height: PropTypes.string,
  background: PropTypes.string,
  border: PropTypes.string,
  fontWeight: PropTypes.string,
  fontSize: PropTypes.string,
  color: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
  borderRadius: PropTypes.string,
};

export const textDateFormatter = (start) => {
  let refined = moment(start).format("YYYY/MM/DD");
  const splittedStartDate = refined?.split("/");
  let startDate = moment(start, "YYYY-MM-DD");
  let startMonthName = startDate.format("MMM");
  return `${startMonthName} ${splittedStartDate[2]} ${splittedStartDate[0]}`;
};

export const states = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];

export const colors = {
  a: "#FCE2AF",
  b: "#FFE7E7",
  c: "#BCEFFF",
  d: "#E0EEF0",
  e: "#FCE2AF",
  f: "#FFE7E7",
  g: "#BCEFFF",
  h: "#E0EEF0",
  i: "#FCE2AF",
  j: "#FFE7E7",
  k: "#BCEFFF",
  l: "#E0EEF0",
  m: "#FCE2AF",
  n: "#FFE7E7",
  o: "#BCEFFF",
  p: "#E0EEF0",
  q: "#FCE2AF",
  r: "#FFE7E7",
  s: "#BCEFFF",
  t: "#E0EEF0",
  u: "#FCE2AF",
  v: "#FFE7E7",
  w: "#BCEFFF",
  x: "#E0EEF0",
  y: "#FCE2AF",
  z: "#BCEFFF",
};

export function getIconUser(first = "A", second = "B") {
  const color = colors[first.trim()[0].toLowerCase()];
  const letter =
    second?.trim().length > 0 && second?.trim()[0] !== "B"
      ? first?.trim()[0]?.toUpperCase() + second?.trim()[0]?.toUpperCase()
      : first?.trim()[0]?.toUpperCase();
  return {
    letter,
    color,
  };
}
export function getCollabIconUser(first = "A", second = "B") {
  const letter =
    second?.trim().length > 0 && second?.trim()[0] !== "B"
      ? first?.trim()[0].toUpperCase() + second?.trim()[0].toUpperCase()
      : first?.trim()[0].toUpperCase();

  return letter;
}
export function getSingleIconUser(first = "A") {
  const letter = first?.trim()[0].toUpperCase();
  return letter;
}

export function NameIcon({ first, second, scale = 1, margin, width, height }) {
  const { letter, color } = getIconUser(first, second);
  return (
    <Wrapper
      scale={scale}
      color={color}
      margin={margin}
      width={width}
      height={height}
    >
      {letter}
    </Wrapper>
  );
}

export function NameReceiverIcon({
  first,
  second,
  scale = 0.2,
  margin,
  width,
  height,
}) {
  const { letter, color } = getIconUser(first, second);
  return (
    <ReceiverWrapper scale={scale} color={color} margin={margin}>
      {letter}
    </ReceiverWrapper>
  );
}

const ReceiverWrapper = styled(Avatar)`
  ${({ theme, color, scale, margin }) => `
    background: ${color || theme.palette.secondary.main};
    color: #545454;
    height: 25px;
    width: 25px;

    font-weight: bold;
    font-size: 12.25px;
  line-height: 23px;
  margin : ${margin};
  `}
`;

const Wrapper = styled(Avatar)`
  ${({ theme, color, scale, margin }) => `
    background: ${color || theme.palette.secondary.main};
    color: #545454;
    height: ${(props) =>
      props.height ? `${scale * props.height}px` : `${scale * 50}px`};
    width: ${(props) =>
      props.width ? `${scale * props.width}px` : `${scale * 50}px`};

    font-weight: bold;
    font-size: 16.25px;
  line-height: 23px;
  margin : ${margin};
  `}
`;
