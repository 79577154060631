import { Box, styled } from "@material-ui/core";
import { SignalCellular4Bar } from "@material-ui/icons";
import { CustomText, NameIcon } from "common/Reusables";
import React from "react";
import { BeatLoader } from "react-spinners";
import { ChatIcon, OnlineIcon, PinIcon } from "../Icons";
import { conversationTimeFormat } from "../util";
const HoverBox = styled(Box)({
  "&:hover": {
    backgroundColor: "#EAF8FA",
  },
});
const UserCard = ({
  employee,
  isChat,
  isSearch,
  handleStartChat,
  selected,
  setSelected,
  unread,
  handleMessage,
  time,
  onlineUsers,
  notification,
  isTyping,
  setShowInfo,
  setNotReady,
  showInfo,
  notReady,
  user,
}) => {
  const [unreadCount, setUnreadCount] = React.useState(unread);
  const [lastMessage, setLastMessage] = React.useState({
    message: employee?.last_message?.message,
    time: time,
    message_type: employee?.last_message?.message_type,
  });

  React.useEffect(() => {
    if (notification && notification?.chat_room === employee?.room_id) {
      setLastMessage({
        message: notification?.message,
        time: conversationTimeFormat(notification?.created_at),
        message_type: notification?.message_type,
      });

      if (notification?.sender !== user?.id) {
        setUnreadCount(unreadCount + 1);
      }
    }
    return () => {
      setUnreadCount(0);
    };
  }, [notification]);
  return (
    <HoverBox
      onClick={
        isChat
          ? () => {
              handleMessage(employee);
              setUnreadCount(0);
            }
          : employee?.can_access_myedge
          ? () => {
              handleStartChat(employee);
            }
          : (e) => {
              e.preventDefault();
              if (employee.id === notReady) {
                setNotReady(employee.id);
                setShowInfo(!showInfo);
              } else {
                setNotReady(employee.id);
                setShowInfo(true);
              }
            }
      }
      sx={{
        position: "relative",
        bgcolor: isChat && employee?.room_id === selected ? "#D6F2F5" : "#fff",
        width: "100%",
        borderBottom: "1px solid #E1E1E1",
        paddingBottom: "13px",
        paddingTop: "14px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "7px",
        paddingLeft: isChat ? "0px" : "10px",
        paddingRight: isChat ? "0px" : "40px",
        cursor: isChat
          ? "pointer"
          : employee?.can_access_myedge
          ? "pointer"
          : "not-allowed",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            opacity: isChat ? 1 : employee?.can_access_myedge ? 1 : 0.78,
          }}
        >
          {employee?.photo ? (
            <img
              src={employee?.photo}
              alt={employee.first_name[0]}
              height="40px"
              width="40px"
              style={{
                borderRadius: "100%",
              }}
            />
          ) : (
            <NameIcon
              first={employee?.first_name}
              second={employee?.last_name}
            />
          )}
          {onlineUsers?.includes(employee?.id) && (
            <Box
              sx={{
                position: "absolute",
                top: "25px",
                right: "5px",
              }}
            >
              <OnlineIcon />
            </Box>
          )}
        </Box>

        <Box width={"100%"}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <CustomText
                color={
                  isChat
                    ? "#545454"
                    : !employee?.can_access_myedge
                    ? "#ADADAD"
                    : "#545454"
                }
                fontSize="14px"
                fontWeight="600"
                fontFamily="Black Sans"
                style={{
                  textTransform: "capitalize",
                }}
              >{`${employee?.first_name}  ${employee?.last_name}`}</CustomText>
              {employee?.isPinned && <PinIcon />}
            </Box>
            <CustomText
              fontSize="12px"
              fontFamily="Black Sans"
              color="#000"
              className="job"
            >
              {lastMessage?.time}
            </CustomText>
          </Box>
          {isChat ? (
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {isTyping &&
              isTyping?.room_id === employee?.room_id &&
              isTyping?.message === "true" ? (
                <BeatLoader color="#D9D9D9" size={10} />
              ) : lastMessage.message_type === "text" ? (
                <CustomText
                  fontSize="14px"
                  fontFamily="Black Sans"
                  color="rgba(84, 84, 84, 0.60)"
                  className="preview-text"
                  dangerouslySetInnerHTML={
                    notification &&
                    notification?.chat_room === employee?.room_id
                      ? {
                          __html: notification?.message,
                        }
                      : {
                          __html: employee?.last_message?.message,
                        }
                  }
                ></CustomText>
              ) : lastMessage.message_type === "image" ? (
                <i>Sent an image</i>
              ) : lastMessage.message_type === "pdf" ||
                lastMessage.message_type === "doc" ? (
                <i>Sent a file</i>
              ) : lastMessage.message_type === "video" ? (
                <i>Sent a video</i>
              ) : lastMessage.message_type === "shared_task" ? (
                <i>Task is shared</i>
              ) : (
                <></>
              )}

              {unreadCount > 0 && (
                <Box
                  sx={{
                    bgcolor: "#849EE1",
                    height: "16px",
                    width: "16px",
                    borderRadius: "16px",
                    fontSize: "10px",
                    color: "#fff",
                    padding: "2px 5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {unreadCount}
                </Box>
              )}
            </Box>
          ) : (
            <CustomText
              fontSize="14px"
              fontFamily="Black Sans"
              color="rgba(84, 84, 84, 0.60)"
              style={{ textTransform: "capitalize" }}
              className="job"
            >
              {employee?.job?.title}
            </CustomText>
          )}
        </Box>
      </Box>
      {isSearch && (
        <Box
          sx={{
            alignSelf: "center",
            color: "red",
          }}
        >
          {employee?.can_access_myedge ? (
            <ChatIcon />
          ) : (
            <ChatIcon color="#E8E8E8" />
          )}
        </Box>
      )}

      {!isChat && !isSearch && (
        <CustomText
          fontSize="14px"
          fontFamily="Black Sans"
          color={employee?.can_access_myedge ? "#545454" : "#E3E2E2"}
          style={{ textTransform: "capitalize" }}
          className="job"
          textAlign={"center"}
        >
          {employee?.department?.name}
        </CustomText>
      )}

      {employee.id === notReady && showInfo && (
        <Box
          sx={{
            position: "absolute",
            right: "10px",
            top: "55px",
            height: "51px",
            width: "185px",
            borderRadius: "10px",
            bgcolor: "#D6F2F5",
            opacity: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingX: "14px",
            zIndex: 9999,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "-10px",
              color: "#D6F2F5",
            }}
          >
            <SignalCellular4Bar color="#D6F2F5" />
          </Box>
          <CustomText
            fontFamily="Black Sans"
            fontSize="12px"
            fontWeight="600"
            color="#1E727B"
          >
            Employee currently has no MyEdge access
          </CustomText>
        </Box>
      )}
    </HoverBox>
  );
};

export default UserCard;
