import { Box } from "@material-ui/core";
import { Close, KeyboardArrowDown } from "@material-ui/icons";
import { Typography } from "antd";
import { CustomText, NameIcon } from "common/Reusables";
import Tiptap from "components/Tiptap/Tiptap";
import MarkdownIt from "markdown-it";
import EmptyState from "../EmptyState";
import React, { useMemo } from "react";
import {
  AddUserIcon,
  AttachFileIcon,
  DeleteIcon,
  LeaveGroupIcon,
  MuteIcon,
  Pin,
  SendIcon,
  SettingsIcon,
  UserIcon,
} from "../Icons";
import "../style.css";
import GroupSetting from "./GroupSetting";
import Members from "./Members";
import Messages from "./Messages";
import SharedFiles from "./SharedFiles";
import UserActionDialog from "./UserActionDialog";
import { CircularProgress } from "@mui/material";
const GroupMessagePanel = ({
  firstElementRef,
  isGroupFetchingNextPage,
  conversations,
  groupConversation,
  activeGroup,
  handleSendMessageToGroup,
  isLoading,
  handleChatActions,
  setOpen,
  open,
  editorTipTap,
  openEmoji,
  setOpenEmoji,
  handleFileUpload,
  setConversationsParams,
  conversationsParams,
  message,
  handleKeyDown,
  groupFiles,
  groupDetails,
  groupSharedFiles,
  isTypingGroup,
  user,
  isGroupPinned,
  parentMessage,
  setParentMessage,
  messageAction,
  setMessageAction,
  handleActions,
  groupInfo,
  isGroupMute,
  employees,
  handleMessage,
  chatRoomUpdateMutation,
  startChatMutation,
  setActionType,
  editMessage,
  messageId,
  setMessageId,
  setActiveContent,
  setGroupId,
  setActiveGroup,
  setNotification,
  openSearch,
  setOpenSearch,
  uploadFileMutation,
}) => {
  const adminTabs = ["Chat", "Files", "Members", "Settings"];
  const memberTabs = ["Chat", "Files", "Members"];
  const currentTabs = activeGroup?.group_admin ? adminTabs : memberTabs;
  const [active, setActive] = React.useState("Chat");
  const messagesEndRef = React.useRef(null);
  const uploadRef = React.useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [groupConversation]);

  React.useEffect(() => {
    if (activeGroup !== null) {
      setActive("Chat");
    }
  }, [activeGroup]);

  const renderMarkdown = () => {
    const md = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
      breaks: true,
    });

    let content = parentMessage?.message;
    if (content.includes("\\\n")) {
      content = content.replaceAll("\\\n", "<br/>");
    } else if (content.includes("\n")) {
      content = content.replaceAll("\n", "<br/>");
    }
    return { __html: md.render(content) };
  };

  const messageProps = useMemo(
    () => ({
      firstElementRef,
      conversations,
      activeGroup,
      isLoading,
      handleChatActions,
      setConversationsParams,
      conversationsParams,
      editMessage,
      parentMessage,
      setParentMessage,
      messageAction,
      setMessageAction,
      uploadRef,
      // handleSendMessageToGroup,
      openEmoji,
      setOpenEmoji,
      messageId,
      setMessageId,
      // handleFileUpload,
      // editorTipTap,
      // handleKeyDown,
    }),
    [
      firstElementRef,
      conversations,
      activeGroup,
      isLoading,
      handleChatActions,
      setConversationsParams,
      conversationsParams,
      editMessage,
      parentMessage,
      setParentMessage,
      messageAction,
      setMessageAction,
      uploadRef,
      // handleFileUpload,
      // handleSendMessageToGroup,
      // editorTipTap,
      openEmoji,
      setOpenEmoji,
      // handleKeyDown,
      messageId,
      setMessageId,
    ]
  );

  const activeContent = (activeTab) => {
    switch (activeTab) {
      case "Chat":
        return (
          <Box
            sx={{
              width: "100%",
              bgcolor: "#fff",
              height: "100%",
              padding: "24px 10px",
            }}
          >
            <Messages
              isFetchingNextPage={isGroupFetchingNextPage}
              isGroup={true}
              messages={groupConversation}
              {...messageProps}
            />
            {isTypingGroup &&
              isTypingGroup?.room_id === activeGroup?.id &&
              isTypingGroup?.message !== "false" &&
              isTypingGroup?.sender_id !== user?.id && (
                <CustomText
                  fontSize="14px"
                  fontFamily="Black Sans"
                  color="rgba(84, 84, 84, 0.60)"
                >
                  {isTypingGroup?.message}
                </CustomText>
              )}
            <div ref={messagesEndRef} />
          </Box>
        );
      case "Files":
        return <SharedFiles roomId={activeGroup?.room_id} files={groupFiles} />;
      case "Members":
        return (
          <Members
            {...{
              groupDetails,
              groupInfo,
              employees,
              handleMessage,
              chatRoomUpdateMutation,
              startChatMutation,
              setActionType,
              setActiveContent,
              setGroupId,
              setActiveGroup,
              setNotification,
            }}
          />
        );
      case "Settings":
        return <GroupSetting {...{ groupDetails }} />;
      default:
        return (
          <Box
            sx={{
              width: "100%",
              bgcolor: "#fff",
              height: "100%",
              padding: "24px 10px",
            }}
          >
            <Messages
              isFetchingNextPage={isGroupFetchingNextPage}
              messages={groupConversation}
              // {...{
              //   firstElementRef,
              //   conversations,
              //   activeGroup,
              //   isLoading,
              //   handleChatActions,
              //   setConversationsParams,
              //   conversationsParams,
              //   editMessage,
              //   parentMessage,
              //   setParentMessage,
              //   messageAction,
              //   setMessageAction,
              //   uploadRef,
              //   handleFileUpload,
              //   message,
              //   handleSendMessageToGroup,
              //   editorTipTap,
              //   openEmoji,
              //   setOpenEmoji,
              //   handleKeyDown,
              //   messageId,
              //   setMessageId,
              // }}
              {...messageProps}
            />

            {isTypingGroup &&
              isTypingGroup?.room_id === activeGroup?.id &&
              isTypingGroup?.message !== "false" &&
              isTypingGroup?.sender_id !== user?.id && (
                <CustomText
                  fontSize="14px"
                  fontFamily="Black Sans"
                  color="rgba(84, 84, 84, 0.60)"
                >
                  {isTypingGroup?.message}...
                </CustomText>
              )}
            <div ref={messagesEndRef} />
          </Box>
        );
    }
  };
  return (
    <Box
      sx={{
        width: "654px",
        bgcolor: "white",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {!activeGroup && (
        <Box
          sx={{
            width: "100%",
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            height: "calc(100vh - 65px)",
            overflowY: "scroll",
          }}
        >
          {" "}
          <EmptyState {...{ openSearch, setOpenSearch, setActiveContent }} />
        </Box>
      )}
      {activeGroup && (
        <>
          <Box
            sx={{
              bgcolor: "#D6F2F5",
              width: "100%",
              height: "65px",
              display: "flex",
              gap: "15px",
              alignItems: "center",
              paddingLeft: "13px",
              position: "relative",
            }}
          >
            <Box
              onClick={() => {
                setOpen(!open);
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
                bgcolor: "#ADE5EB",
                padding: "6px 15px",
                borderRadius: "6px",
              }}
            >
              <NameIcon first={`${activeGroup?.name[0]}`} />
              <Typography>{`${activeGroup?.name}`}</Typography>
              <KeyboardArrowDown />
            </Box>
            {open && (
              <UserActionDialog
                width="210px"
                handleActions={(action) => {
                  handleActions(action);
                  setOpen(false);

                  if (action === "View member") {
                    setActive("Members");
                  } else if (action === "Settings") {
                    setActive("Settings");
                  }
                }}
                actions={
                  activeGroup?.group_admin
                    ? [
                        { label: "View member", icon: <UserIcon /> },
                        { label: "Add member", icon: <AddUserIcon /> },

                        {
                          label: isGroupPinned ? "Unpin chat" : "Pin chat",
                          icon: isGroupPinned ? <Pin /> : <Pin />,
                        },
                        // {
                        //   label: "Leave group",
                        //   icon: <LeaveGroupIcon />,
                        // },

                        {
                          label: isGroupMute
                            ? "Unmute Group chat"
                            : "Mute Group chat",
                          icon: <MuteIcon />,
                        },
                        {
                          label: "Settings",
                          icon: <SettingsIcon />,
                        },
                        { label: "Delete", icon: <DeleteIcon /> },
                      ]
                    : [
                        { label: "View member", icon: <UserIcon /> },

                        {
                          label: isGroupPinned ? "Unpin chat" : "Pin chat",
                          icon: isGroupPinned ? <Pin /> : <Pin />,
                        },
                        {
                          label: "Leave group",
                          icon: <LeaveGroupIcon />,
                        },

                        {
                          label: isGroupMute
                            ? "Unmute Group chat"
                            : "Mute Group chat",
                          icon: <MuteIcon />,
                        },
                      ]
                }
                {...{
                  setOpen,
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              height: "36px",
              width: "100%",
              borderBottom: "1px solid #E1E1E1",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginTop: "10px",

              marginX: "10px",
            }}
          >
            {currentTabs.map((tab, index) => (
              <Box
                key={index}
                onClick={() => {
                  if (tab.includes("Files")) {
                    groupSharedFiles.refetch();
                    setActive(tab);
                  }

                  setActive(tab);
                }}
                sx={{
                  padding: "8px 32px",
                  bgcolor: active === tab ? "#F3F3F3" : "#fff",
                  borderRadius: "5px 5px 0px 0px",
                  cursor: "pointer",
                }}
              >
                {tab}
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              width: "100%",
              bgcolor: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: active === "Chat" && "center",

              height: "calc(100vh - 65px)",
              overflowY: "scroll",
            }}
          >
            {activeContent(active)}
          </Box>
          {/* tip tap here */}
          {active === "Chat" && (
            <Box
              sx={{
                bgcolor: "#F3F3F3",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "9px",
                alignItems: "center",
                padding: "20px 24px",
              }}
            >
              {messageAction === "Quote reply" && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "4px 17px 5px 15px",
                    gap: "45px",
                    bgcolor: "#fff",
                    borderRadius: "10px",
                  }}
                >
                  <CustomText
                    fontSize="14px"
                    color="#1D1E22"
                    fontWeight="400"
                    fontFamily="Black Sans"
                    dangerouslySetInnerHTML={{
                      __html: parentMessage?.message,
                    }}
                  ></CustomText>

                  <Box
                    onClick={() => {
                      setMessageAction(null);
                      setParentMessage(null);
                    }}
                    sx={{
                      cursor: "pointer",
                      alignSelf: "center",
                    }}
                  >
                    <Close />
                  </Box>
                </Box>
              )}{" "}
              <Box
                sx={{
                  display: "flex",
                  gap: "11px",
                  alignItems: "center",
                }}
              >
                {uploadFileMutation?.isLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: "#3AE180",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => uploadRef.current.click()}
                  >
                    <input
                      ref={uploadRef}
                      type="file"
                      id="file"
                      style={{ display: "none" }}
                      accept="video/*,image/*,.doc, .docx, .pdf, .txt, .ppt, .pptx, .xls, .xlsx, .csv"
                      onChange={handleFileUpload}
                    />
                    <AttachFileIcon />
                  </Box>
                )}

                <Tiptap
                  {...{
                    editorTipTap,
                    openEmoji,
                    setOpenEmoji,
                    handleKeyDown,
                    message,
                  }}
                />
                <Box
                  onClick={message ? handleSendMessageToGroup : null}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <SendIcon fillColor={message ? "#4069D0" : "#E1E1E1"} />
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default GroupMessagePanel;
