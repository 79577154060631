import { Box } from "@mui/material";
import { CustomText } from "common/Reusables";
import {
  CommentIcon,
  OpenChatIcon,
} from "Pages/AuthPages/Dashboard/Chat/Icons";

const FloatingButton = ({ setOpenChat, userInformation }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "0",
        left: "75%",
        height: "56px",
        display: "flex",
        width: "320px",
        padding: "11px 15px",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "7px 7px 0px 0px",
        bgcolor: "#4069D0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "6px",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <Box position="relative">
          {/* {userInformation?.photo ? (
            <img
              src={userInformation?.photo}
              alt="profile"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
            />
          ) : (
            <NameIcon first={userInformation?.first_name[0]} />
          )} */}
          <CommentIcon />
          {/* <Box
            sx={{
              position: "absolute",
              top: "25px",
              right: "5px",
            }}
          >
            <OnlineIcon />
          </Box> */}
        </Box>
        <CustomText
          fontSize="20px"
          fontFamily="Black Sans"
          fontWeight="600"
          color="#fff"
        >
          Chat
        </CustomText>
      </Box>

      <Box
        onClick={() => setOpenChat(true)}
        sx={{
          cursor: "pointer",
        }}
      >
        <OpenChatIcon />
      </Box>
    </Box>
  );
};

export default FloatingButton;
