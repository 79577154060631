import { Box, makeStyles } from "@material-ui/core";
import { EditorContent } from "@tiptap/react";

import React from "react";
import { useDebounce } from "use-debounce";
import TextFormatter from "./TextFormatter";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",

    borderRadius: 4,
    overflowY: "scroll",
    //   ".ProseMirror": {
    //     p: 6,
    //     h: 20,
    //     "p.is-editor-empty:first-child::before": {
    //       content: "attr(data-placeholder)",
    //       color: "rgba(17, 17, 17, 0.3)",
    //       pointerEvents: "none",
    //       height: 100,
    //       fontStyle: "italic",
    //       float: "left",
    //     },
    //   },

    //   ".ProseMirror:focus": {
    //     outline: "none",
    //   },
    // },
    // menuContainer: {
    //   backgroundColor: "#FAFAFA",
    //   p: 12,
    // },
    // menuContent: {
    //   display: "flex",
    //   ".is-active": {
    //     backgroundColor: "#c7c7c7a9",
    //   },
  },
});
const Tiptap = ({
  editorTipTap,
  openEmoji,
  setOpenEmoji,
  handleKeyDown,
  message,
}) => {
  const classes = useStyles();

  const [editorHeight, setEditorHeight] = React.useState("auto");
  const [debouncedEditorContent] = useDebounce(message, 300); // Debounce the editor content

  // Update the editor height when the debounced editor content changes
  React.useEffect(() => {
    const editorElement = document.querySelector(".ProseMirror");
    if (editorElement) {
      setEditorHeight(`${editorElement.scrollHeight}px`);
    }
  }, [debouncedEditorContent]);

  return (
    <div
      style={{
        height: editorHeight === "0px" ? "150px" : editorHeight,
        maxHeight: "200px",
        width: "528px",
        background: "white",
        borderRadius: "8px",
        padding: "10px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <TextFormatter {...{ openEmoji, setOpenEmoji, editorTipTap }} />
      </Box>
      <div
        className={classes.container}
        onKeyDown={
          editorTipTap.isActive("bulletList") ||
          editorTipTap.isActive("orderedList")
            ? null
            : handleKeyDown
        }
      >
        <EditorContent editor={editorTipTap} />
      </div>
    </div>
  );
};

export default Tiptap;
