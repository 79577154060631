import React from "react";
import FileViewer from "react-file-viewer";
import { Box } from "@material-ui/core";
import "../style.css";
const FilePreview = ({ filePath, fileType, imageUrl }) => {
  return (
    <Box
      sx={{
        height: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: "90%",
          width: "100%",
        }}
      >
        {imageUrl && (
          <FileViewer
            fileType={fileType} // Change this according to the file type
            filePath={filePath}
            onError={console.error}
          />
        )}
      </div>

      {/* <Box
        sx={{
          height: "45px",
          width: "120px",
          bgcolor: "#F9F9F9",
        }}
      >
        <a href={imageUrl} target="_blank" rel="noreferrer">
          Download
        </a>
      </Box> */}
    </Box>
  );
};

export default FilePreview;
