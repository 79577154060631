import { Box } from "@material-ui/core";
import React, { useContext } from "react";
import { Each } from "../Each";
import Member from "./Member";
import { AuthContext } from "api/AuthContext";

const Members = ({
  groupDetails,
  groupInfo,
  employees,
  handleMessage,
  chatRoomUpdateMutation,
  startChatMutation,
  setActionType,
  setActiveContent,
  setGroupId,
  setActiveGroup,
  setNotification,
}) => {
  const { user } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [selectedMember, setSelectedMember] = React.useState(null);
  const self = groupDetails?.members?.find((item) => item.id === user.id);
  const excludedMembers = groupInfo?.members.filter((item) => item !== user.id);

  return (
    <Box
      sx={{
        width: "100%",
        padding: "10px",
      }}
    >
      <Each
        of={[
          self,
          ...employees.filter((item) => excludedMembers.includes(item?.id)),
        ]}
        render={(member) => (
          <Member
            isAdmin={groupInfo.group_admin.includes(member?.id)}
            employee={member}
            {...{
              selectedMember,
              setSelectedMember,
              setOpen,
              open,
              groupDetails,
              groupInfo,
              handleMessage,
              chatRoomUpdateMutation,
              startChatMutation,
              setActionType,
              setActiveContent,
              setGroupId,
              setActiveGroup,
              setNotification,
            }}
          />
        )}
      />
    </Box>
  );
};

export default Members;
