import { Box, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { CustomText } from "common/Reusables";
import React from "react";

const InfoDialog = ({ setShowChatInfo }) => {
  return (
    <Box
      sx={{
        height: "304px",
        width: "467px",
      }}
    >
      <Box
        sx={{
          bgcolor: "#EBEFFA",
          borderRadius: "15px 15px 0px 0px",
          padding: "24px 28px 21px 28px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomText fontSize="20px" fontWeight="600" color="#171717">
          Chat New Home
        </CustomText>

        <Button
          onClick={() => {
            setShowChatInfo(false);
            localStorage.setItem("chatInfoDialog", "true");
          }}
          style={{
            border: "none",
            background: "none",
          }}
        >
          <Close />
        </Button>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "51px",
          padding: "20px 26px",
          borderRadius: "0px 0px 15px 15px",
          bgcolor: "#FFF",
        }}
      >
        <CustomText fontSize="14px" fontWeight="400" color="#878787">
          We've moved Chat to a new spot! You'll now find it conveniently
          located at the bottom right of your screen. It's now always accessible
          on all screens, no matter what you're doing.
          <br />
          <br />
          Your chats and contacts are safe and sound – just where you left them.
        </CustomText>

        <Button
          onClick={() => {
            setShowChatInfo(false);
            localStorage.setItem("chatInfoDialog", "true");
          }}
          style={{
            display: "flex",
            width: "424px",
            height: "42px",
            padding: "10px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            background: "#4069D0",
            borderRadius: "5px",
            textTransform: "capitalize",
            color: "#FFF",

            textAlign: "center",
            fontFamily: "Black Sans",
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          Got It
        </Button>
      </Box>
    </Box>
  );
};

export default InfoDialog;
