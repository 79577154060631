import { Box, Typography } from "@material-ui/core";
import { Close, KeyboardArrowDown } from "@material-ui/icons";
import { CircularProgress } from "@mui/material";
import { CustomText, NameIcon } from "common/Reusables";
import Tiptap from "components/Tiptap/Tiptap";
import _ from "lodash";
import MarkdownIt from "markdown-it";
import React, { lazy, useCallback, useMemo } from "react";
import { BeatLoader } from "react-spinners";
import UserActionDialog from "./components/UserActionDialog";
import EmptyState from "./EmptyState";
import {
  AttachFileIcon,
  BlockIcon,
  ClearIcon,
  DeleteIcon,
  MuteIcon,
  Pin,
  ReceiverVertIcon,
  SendIcon,
} from "./Icons";
import "./style.css";

const Messages = lazy(() => import("./components/Messages"));
const SharedFiles = lazy(() => import("./components/SharedFiles"));

const MessagePanel = ({
  isFetchingNextPage,
  firstElementRef,
  conversations,
  openSearch,
  setOpenSearch,
  messages,
  activeUser,
  handleSendMessage,
  isLoading,
  chatRoomUpdateMutation,
  deleteChatMutation,
  handleActions,
  setOpen,
  open,
  handleChatActions,
  editorTipTap,
  openEmoji,
  setOpenEmoji,
  handleFileUpload,
  message,
  handleKeyDown,
  isPinned,
  files,
  sharedFiles,
  setActiveContent,
  isTyping,
  parentMessage,
  setParentMessage,
  messageAction,
  setMessageAction,
  editMessage,
  messageId,
  isBlocked,
  setMessageId,
  isSuccess,
  messagesEndRef,
  isInitialLoad,
  setIsInitialLoad,
  uploadFileMutation,
}) => {
  const [activeTab, setActiveTab] = React.useState("Chat");

  const uploadRef = React.useRef(null);

  const chatContainerRef = React.useRef();
  const scrollPositionRef = React.useRef(0);
  const previousHeightRef = React.useRef(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    _.throttle(() => {
      if (chatContainerRef.current) {
        scrollPositionRef.current = chatContainerRef.current.scrollTop;
        previousHeightRef.current = chatContainerRef.current.scrollHeight;
      }
    }, 300),
    []
  );

  React.useEffect(() => {
    if (isSuccess || !messagesEndRef.current) {
      if (chatContainerRef.current) {
        const newHeight = chatContainerRef.current.scrollHeight;
        if (isInitialLoad) {
          setIsInitialLoad(false);
          chatContainerRef.current?.scrollIntoView({ behavior: "auto" });
        } else {
          // Adjust scroll position when new data is prepended
          chatContainerRef.current.scrollTop =
            newHeight - previousHeightRef.current + scrollPositionRef.current;
        }
      }
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, [messages, isSuccess, isInitialLoad]);

  const handleTabChange = React.useCallback((tab) => {
    setActiveTab(tab);
  }, []);

  const renderMarkdown = () => {
    const md = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
      breaks: true,
    });

    let content = parentMessage?.message;
    if (content.includes("\\\n")) {
      content = content.replaceAll("\\\n", "<br/>");
    } else if (content.includes("\n")) {
      content = content.replaceAll("\n", "<br/>");
    }
    return { __html: md.render(content) };
  };

  const messagesProps = useMemo(
    () => ({
      isFetchingNextPage,
      firstElementRef,
      activeUser,
      messages,
      isLoading,
      handleChatActions,
      conversations,
      parentMessage,
      setParentMessage,
      editMessage,
      messageId,
      setMessageId,
      isSuccess,
    }),
    [
      // isFetchingNextPage,
      // firstElementRef,
      // activeUser,
      // messages,
      // isLoading,
      // handleChatActions,
      // conversations,
      // parentMessage,
      // setParentMessage,
      // editMessage,
      // messageId,
      // setMessageId,
      // isSuccess,
    ]
  );
  const defaultMessagesProps = useMemo(
    () => ({
      isFetchingNextPage,
      firstElementRef,
      conversations,
      activeUser,
      messages,
      isLoading,
      handleChatActions,
      parentMessage,
      setParentMessage,
      handleSendMessage,
      uploadRef,
      handleFileUpload,
      message,
      editorTipTap,
      openEmoji,
      setOpenEmoji,
      handleKeyDown,
      messageId,
      setMessageId,
      isSuccess,
    }),
    [
      // isFetchingNextPage,
      // firstElementRef,
      // conversations,
      // activeUser,
      // messages,
      // isLoading,
      // handleChatActions,
      // parentMessage,
      // setParentMessage,
      // handleSendMessage,
      // uploadRef,
      // handleFileUpload,
      // // message,
      // // editorTipTap,
      // openEmoji,
      // setOpenEmoji,
      // handleKeyDown,
      // messageId,
      // setMessageId,
      // isSuccess,
    ]
  );
  const activeContent = (activeTab) => {
    switch (activeTab) {
      case "Chat":
        return (
          <Box
            sx={{
              width: "100%",
              bgcolor: "#fff",
              height: "100%",
              padding: "24px 10px",
            }}
          >
            <Messages
              {...{
                isFetchingNextPage,
                firstElementRef,
                activeUser,
                messages,
                isLoading,
                handleChatActions,
                conversations,
                parentMessage,
                setParentMessage,
                editMessage,
                messageId,
                setMessageId,
                isSuccess,
              }}
            />
            {isTyping?.room_id === activeUser?.room_id &&
              isTyping?.message === "true" && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddingBottom: "10px",
                  }}
                >
                  {activeUser?.photo ? (
                    <img
                      src={activeUser?.photo}
                      alt={activeUser?.first_name[0]}
                      height="24px"
                      width="24px"
                      style={{
                        borderRadius: "100%",
                      }}
                    />
                  ) : (
                    <NameIcon
                      first={activeUser?.first_name}
                      second={activeUser?.last_name}
                    />
                  )}
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <ReceiverVertIcon />
                    <Box
                      sx={{
                        width: "91px",
                        borderRadius: "0px 4px 4px 4px",
                        bgcolor: "#f3f3f3",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        position: "relative",
                        fontSize: "14px",
                        fontFamily: "Black Sans",
                      }}
                    >
                      <BeatLoader color="#D9D9D9" size={10} />
                    </Box>
                  </Box>
                </Box>
              )}
            <div ref={messagesEndRef} />
          </Box>
        );
      case "Files Shared":
        return (
          <SharedFiles
            roomId={activeUser?.room_id}
            files={files}
            {...{ sharedFiles }}
          />
        );
      default:
        return (
          <Box
            sx={{
              width: "100%",
              bgcolor: "#fff",
              height: "100%",
              padding: "24px 10px",
            }}
          >
            <Messages
              {...{
                isFetchingNextPage,
                firstElementRef,
                conversations,
                activeUser,
                messages,
                isLoading,
                handleChatActions,
                parentMessage,
                setParentMessage,
                handleSendMessage,
                uploadRef,
                handleFileUpload,
                message,
                editorTipTap,
                openEmoji,
                setOpenEmoji,
                handleKeyDown,
                messageId,
                setMessageId,
                isSuccess,
              }}
            />

            {isTyping?.room_id === activeUser?.room_id &&
              isTyping?.message === "true" && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  {activeUser?.photo ? (
                    <img
                      src={activeUser?.photo}
                      alt={activeUser?.first_name[0]}
                      height="24px"
                      width="24px"
                      style={{
                        borderRadius: "100%",
                      }}
                    />
                  ) : (
                    <NameIcon
                      first={activeUser?.first_name}
                      second={activeUser?.last_name}
                    />
                  )}
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <ReceiverVertIcon />
                    <Box
                      sx={{
                        width: "91px",
                        borderRadius: "0px 4px 4px 4px",
                        bgcolor: "#f3f3f3",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        position: "relative",
                        fontSize: "14px",
                        fontFamily: "Black Sans",
                      }}
                    >
                      <BeatLoader color="#D9D9D9" size={10} />
                    </Box>
                  </Box>
                </Box>
              )}
            <div ref={messagesEndRef} />
          </Box>
        );
    }
  };
  return (
    <Box
      sx={{
        width: "654px",
        bgcolor: "white",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {!activeUser && (
        <Box
          sx={{
            width: "100%",
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            height: "calc(100vh - 65px)",
            overflowY: "scroll",
          }}
        >
          {" "}
          <EmptyState {...{ openSearch, setOpenSearch, setActiveContent }} />
        </Box>
      )}
      {activeUser && (
        <>
          <Box
            sx={{
              bgcolor: "#D6F2F5",
              width: "100%",
              height: "65px",
              display: "flex",
              alignItems: "center",
              paddingLeft: "13px",
              position: "relative",
            }}
          >
            <Box
              onClick={() => setOpen(!open)}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
                bgcolor: "#ADE5EB",
                padding: "6px 15px",
                borderRadius: "6px",
              }}
            >
              <Typography>{`${activeUser?.first_name} ${activeUser?.last_name}`}</Typography>
              <KeyboardArrowDown />
            </Box>
            {open && (
              <UserActionDialog
                width="210px"
                actions={[
                  {
                    label: isPinned ? "Unpin chat" : "Pin chat",
                    icon: isPinned ? <Pin /> : <Pin />,
                  },

                  {
                    label: isBlocked?.block_one
                      ? "Unblock contact"
                      : "Block contact",
                    icon: <BlockIcon />,
                  },
                  { label: "Clear conversation", icon: <ClearIcon /> },
                  { label: "Mute conversation", icon: <MuteIcon /> },
                  { label: "Delete conversation", icon: <DeleteIcon /> },
                ]}
                {...{
                  setOpen,
                  activeUser,
                  chatRoomUpdateMutation,
                  deleteChatMutation,
                  handleActions,
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              height: "36px",
              width: "100%",
              borderBottom: "1px solid #E1E1E1",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginTop: "10px",

              marginX: "10px",
            }}
          >
            {["Chat", "Files Shared"].map((tab, index) => (
              <Box
                key={index}
                onClick={() => handleTabChange(tab)}
                // onClick={() => {
                //   if (tab.includes("Files")) {
                //     // sharedFiles.refetch();
                //     setActiveTab(tab);
                //   }
                //   chatContainerRef.current?.scrollIntoView({
                //     behavior: "auto",
                //   });
                //   setActiveTab(tab);
                // }}
                sx={{
                  padding: "8px 32px",
                  bgcolor: activeTab === tab ? "#F3F3F3" : "#fff",
                  borderRadius: "5px 5px 0px 0px",
                  cursor: "pointer",
                }}
              >
                {tab}
              </Box>
            ))}
          </Box>

          <Box
            ref={chatContainerRef}
            onScroll={handleScroll}
            sx={{
              width: "100%",
              bgcolor: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: activeTab === "Chat" && "center",

              height: "calc(100vh - 65px)",
              overflowY: "scroll",
            }}
          >
            {activeContent(activeTab)}
          </Box>

          <Box
            sx={{
              bgcolor: "#F3F3F3",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "9px",
              alignItems: "center",
              padding: "20px 24px",
            }}
          >
            {messageAction === "Quote reply" && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "4px 17px 5px 15px",
                  gap: "45px",
                  bgcolor: "#fff",
                  borderRadius: "10px",
                }}
              >
                <CustomText
                  fontSize="14px"
                  color="#1D1E22"
                  fontWeight="400"
                  fontFamily="Black Sans"
                  dangerouslySetInnerHTML={{
                    __html: parentMessage?.message,
                  }}
                ></CustomText>

                <Box
                  onClick={() => {
                    setMessageAction(null);
                    setParentMessage(null);
                  }}
                  sx={{
                    cursor: "pointer",
                    alignSelf: "center",
                  }}
                >
                  <Close />
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                gap: "11px",
                alignItems: "center",
              }}
            >
              {uploadFileMutation?.isLoading ? (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "#3AE180",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => uploadRef.current.click()}
                >
                  <input
                    ref={uploadRef}
                    type="file"
                    id="file"
                    style={{ display: "none" }}
                    accept="video/*,image/*,.doc, .docx, .pdf, .txt, .ppt, .pptx, .xls, .xlsx, .csv"
                    onChange={handleFileUpload}
                  />
                  <AttachFileIcon />
                </Box>
              )}

              <Tiptap
                {...{
                  editorTipTap,
                  openEmoji,
                  setOpenEmoji,
                  handleKeyDown,
                  message,
                }}
              />
              <Box
                onClick={message ? handleSendMessage : null}
                sx={{
                  cursor: "pointer",
                }}
              >
                <SendIcon fillColor={message ? "#4069D0" : "#E1E1E1"} />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default MessagePanel;
