import { Box } from "@material-ui/core";
import Emoji, { gitHubEmojis } from "@tiptap-pro/extension-emoji";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Tiptap from "components/Tiptap/Tiptap";
import React from "react";
import { Markdown } from "tiptap-markdown";
import { AttachFileIcon, SendIcon } from "../Icons";
import { Close } from "@material-ui/icons";
const extensions = [
  StarterKit,
  Underline,
  Emoji.configure({
    emojis: gitHubEmojis,
  }),
  Link.configure({
    openOnClick: false,
    autolink: true,
  }),
  Markdown.configure({
    breaks: false,
    linkify: false,
    tightListClass: "tight",
    tightLists: true,
    html: true,
  }),
];
const EditMessage = ({
  setMessageId,
  text,
  sendEditedMessage,
  handleKeyDown,
  setText,
  parentMessage,
}) => {
  const [openEmoji, setOpenEmoji] = React.useState(false);
  const editorTipTap = useEditor({
    content: parentMessage?.message,
    extensions,
    onUpdate: ({ editor }) => {
      if (editor.isEmpty) {
        setText(null);
      } else {
        const markdown = editorTipTap.getHTML();

        setText(markdown);
      }
    },
  });
  return (
    <Box
      sx={{
        bgcolor: "#F3F3F3",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "9px",
        alignItems: "center",
        padding: "20px 24px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "11px",
          alignItems: "center",
        }}
      >
        {editorTipTap && (
          <Tiptap
            message={text}
            {...{ editorTipTap, openEmoji, setOpenEmoji, handleKeyDown }}
          />
        )}

        <Box
          onClick={() => setMessageId(null)}
          sx={{
            position: "absolute",
            right: "10px",
            top: "5px",
            cursor: "pointer",
          }}
        >
          {" "}
          <Close fillColor={text ? "#4069D0" : "#E1E1E1"} />
        </Box>
        <Box
          onClick={text ? sendEditedMessage : null}
          sx={{
            cursor: "pointer",
          }}
        >
          <SendIcon fillColor={text ? "#4069D0" : "#E1E1E1"} />
        </Box>
      </Box>
    </Box>
  );
};

export default EditMessage;
