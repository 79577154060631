import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import CtaDialog from "./components/CtaDialog";

const EmptyState = ({ openSearch, setOpenSearch, setActiveContent }) => {
  const [show, setShow] = React.useState(false);
  return (
    <Box
      sx={{
        width: "374px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <Typography
        style={{
          fontFamily: "Black Sans",
          fontSize: "16px",
          fontWeight: "400",
          color: "#878787",
          textAlign: "center",
          lineHeight: "142%",
        }}
      >
        Click on the + icon to start a chart with a colleague or click the
        search bar to select an employee or colleague to chat with
      </Typography>
      <Box position="relative">
        <Button
          onClick={() =>
            setShow((prev) => {
              return !prev;
            })
          }
          variant="outlined"
          style={{
            width: "205px",
            height: "45px",
            borderRadius: "10px",
            backgroundColor: "#4069D0",
            fontFamily: "Black Sans",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "normal",
            textTransform: "capitalize",
            marginTop: "20px",
            color: "#fff",
          }}
        >
          Start Chat
        </Button>

        {show && (
          <CtaDialog
            {...{ setShow, openSearch, setOpenSearch, setActiveContent }}
          />
        )}
      </Box>
    </Box>
  );
};

export default EmptyState;
