import React from 'react';
import Tabs from 'components/ButtonTab';

const TasksTab = ({ tab, setTab, availableTabs, tabs,fromTaskHome }) => {
  return (
    <div className=''>
      <Tabs
        tabs={tabs}
        value={tab}
        indicatorColor= {fromTaskHome === 'yes' ? '#fff' :'#D6F2F5'}
        colors={{ others: '#878787',
        active: fromTaskHome === 'yes' ? '#545454': '#2898A4',}}
        onChange={(e, newValue) => setTab(newValue)}
        bg= {fromTaskHome === 'yes' ? '#f2f2f2':'#ffffff'}
        border='1px solid #E1E1E1'
        borderRadius='50px'
        tabWidth='150px'
        tabHeight='40px'
        size='small'
        
        // styles={{tab:{textTransform: 'lowercase'}}}
      />
    </div>
  );
};

export default TasksTab;
