import React from "react";
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Modal } from "components/CustomModal";
import { SearchIcon } from "../Icons";
import { Each } from "../Each";
import Member from "./Member";
import { Close } from "@material-ui/icons";
import CircularLoader from "components/CircularLoader";
import { useClickOutside } from "utils/useClickOutside";

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  button: {
    borderRadius: "10px",
    color: "#fff",
    fontFamily: "Black Sans",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "18px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "none",
      color: "#fff",
    },
  },
});
const AddGroupMember = ({
  setOpenAddMember,
  data,
  groupInfo,
  chatRoomUpdateMutation,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(groupInfo?.members ?? []);
  const dialogref = useClickOutside(setOpenAddMember);
  return (
    <Modal ref={dialogref}>
      <Box
        sx={{
          bgcolor: "white",
          padding: "30px",
          borderRadius: "15px",
          width: "567px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              font: "normal 700 18px/20px Black Sans",
              color: "#545454",
            }}
          >
            Add Employees
          </Typography>

          <Box onClick={() => setOpenAddMember(false)}>
            <Close />
          </Box>
        </Box>
        <Divider
          style={{
            marginTop: "10px",
            marginBottom: "19px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search for employee"
            // value={params?.search}
            // onChange={(e) => {
            //   setParams((prev) => {
            //     return { ...prev, search: e.target.value };
            //   });
            // }}
            style={{
              width: "100%",
              padding: "5px",
              border: "none",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box
          sx={{
            height: "400px",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            paddingX: "10px",
          }}
        >
          <Each
            of={data}
            render={(employee) => (
              <Member
                showCheckbox={true}
                isAdmin={false}
                {...{
                  employee,
                  setSelected,
                  selected,
                }}
              />
            )}
          />
        </Box>
        <Button
          onClick={() => {
            chatRoomUpdateMutation.mutate({
              route: `/groups/${groupInfo.id}/`,
              payload: {
                members: selected,
              },
            });
          }}
          className={classes.button}
          style={{
            width: "501px",
            height: "41px",
            borderRadius: "6px",
            backgroundColor: "#2898A4",
            color: "#fff",
            marginTop: "20px",
            fontSize: "14px",
            fontFamily: "Black Sans",
            fontWeight: "600",
          }}
        >
          {chatRoomUpdateMutation?.isLoading ? (
            <CircularLoader />
          ) : (
            "  Add to Group"
          )}
        </Button>
      </Box>
    </Modal>
  );
};

export default AddGroupMember;
